<template>
    <div class="content">
        <img src="../assets/images/img_012.png">
    </div>
</template>

<script>
    export default {
        name: "Contact"
    }
</script>

<style lang="scss" scoped>
    .content{
        width: 100%;
        img{
            display: block;
            width: 100%;
        }
    }
</style>